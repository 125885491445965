<template>
    <section class="py-16" id="media">
        <div class="block font-bold my-6 text-center">
            <h2 class="text-3xl font-bold mb-2">¿Porqué SAMII?</h2>
        </div>
        <div style="text-align: -webkit-center;">
            <youtube video-id="aDDWZ9yjWX8" ref="youtube" :width="width" :height="height" />
        </div>
    </section>
</template>
<script>
import _ from "lodash";

export default {
    name: 'SamiiMedia',
    data() {
        return {
            width: 640,
            height: 360,
            isVideoPlaying: false,
        }
    },
    created() {
        window.addEventListener('resize', _.debounce(this.resize, 200));
        window.addEventListener('scroll', _.debounce(this.scroll, 200));
        this.resize();
    },
    destroyed() {
        window.removeEventListener('resize', _.debounce(this.resize, 200));
        window.removeEventListener('scroll', _.debounce(this.scroll, 200));
    },
    computed: {
        player() {
            return this.$refs.youtube.player;
        },
        myself() {
            return document.getElementById('media');
        },
    },
    methods: {
        resize() {
            const { outerWidth } = window;
            this.width = outerWidth / 1.25;
            this.height = this.width / 2;
        },
        scroll() {
            const offsetTop = this.myself?.offsetTop;
            if (window.scrollY <= offsetTop && !this.isVideoPlaying) {
                this.player.playVideo();
                this.isVideoPlaying = true;
            }
        }
    },
}
</script>