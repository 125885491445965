<template>
  <public-layout>
    <transition name="fade">
      <samii-hero v-if="miniDelay" />
    </transition>
    <transition name="fade">
      <samii-media v-if="miniDelay" />
    </transition>
    <transition name="fade">
      <samii-features v-if="miniDelay" />
    </transition>
    <transition name="fade">
      <samii-pricing v-if="miniDelay" />
    </transition>
  </public-layout>
</template>
<script>
import PublicLayout from '@/components/layouts/PublicLayout.vue';
import SamiiHero from './SamiiHero.vue';
import SamiiMedia from './SamiiMedia.vue';
import SamiiFeatures from './SamiiFeatures.vue';
import SamiiPricing from './SamiiPricing.vue';

export default {
  name: 'LandingIndex',
  components: {
    PublicLayout,
    SamiiHero,
    SamiiMedia,
    SamiiFeatures,
    SamiiPricing,
  },
  data() {
    return {
      miniDelay: false,
    };
  },
  mounted() {
    this.miniDelay = true;
  }
}
</script>
<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
