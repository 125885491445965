<template>
  <section class="text-gray-600 body-font">
    <div class="container flex flex-col flex-wrap items-center p-5 mx-auto md:flex-row">

      <div
        class="flex flex-col justify-center w-full xl:w-2/5 items-center xl:items-start">
        <h1 class="my-4 text-3xl leading-tight text-center md:text-5xl md:text-left slide-in-bottom-h1">
          <span class=" font-bold">El mejor software</span> <br>para administrar<br>
          <span style="color:rgba(139, 92, 246)" class="font-bold">tu taller de reparación.</span>
        </h1>
        <p class="mb-8 text-base font-medium leading-normal text-center text-gray-400 md:text-xl md:text-left slide-in-bottom-subtitle">
          Toma el control de tu negocio.
        </p>
        <div class="w-full flex flex-col items-start md:flex-row items-center">
          <button
            class="w-full md:w-2/5 px-8 py-4 text-lg text-white bg-purple-500 border-0 rounded shadow-2xl focus:outline-none hover:bg-purple-600"
            @click="$router.push({ name: 'register.index', query: { plan: 'basic' } })">
            Comenzar gratis
          </button>
          <div>
            <ul class="px-4 py-12 font-bold text-center text-gray-400 flex-nowrap md:py-0 md:text-left">
              <li>PRUEBA GRATIS DE 15 DÍAS</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="w-full py-6 overflow-y-hidden xl:w-3/5">
        <img class="w-5/6 mx-auto slide-in-bottom" src="@/assets/img/landing-hero.png">
      </div>

    </div>
  </section>
</template>
<script>
export default {
  name: 'SamiiHero',
}
</script>
