<template>
  <section class="py-16" id="features">
    <div class="block font-bold my-6 text-center">
      <h2 class="text-3xl font-bold mb-2">Características</h2>
    </div>
    <div class="sm:block lg:flex p-4 sm:space-x-0 space-x-4">
      <div class="sm:w-full lg:w-1/2">
        <div class="grid grid-cols-2">
          <div
            v-for="feature in features"
            :key="feature.key"
            class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:border-purple-500 hover:font-bold shadow-lg border border-gray-200 rounded p-8 m-4 text-center cursor-pointer"
            @click="selectFeature(feature)"
            :class="[ selectedFeature === feature.key ? 'bg-purple-500 text-white hover:text-white scale-110' : 'hover:text-purple-500']">
            <div class="w-full flex flex-row" style="place-content: center; align-items: center; justify-content: space-evenly;">
              <i class="text-5xl grid" :class="feature.image"></i>
              <span class="text-lg grid">
                {{ feature.label }}
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:w-full lg:w-1/2">
        <div class="shadow-2xl border border-gray-200- rounded p-8 m-4">
          <div v-if="selectedFeature === 'orders'" class="space-y-4">
            <strong class="text-lg">Órdenes de trabajo</strong>
            <ul>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Administra tus órdenes de trabajo
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Notifica a tus clientes el estatus de sus reparaciones
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Crea las ordenes a tu medida
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Toma fotos de las reparacion
              </li>
            </ul>
          </div>
          <div v-if="selectedFeature === 'products'" class="space-y-4">
            <strong class="text-lg">Inventario</strong>
            <p>Administra tus inventarios de una manera sencilla.</p>
            <ul>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Se actualiza a la par de las órdenes.
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Inventario por cada sucursal
              </li>
            </ul>
          </div>
          <div v-if="selectedFeature === 'clients'" class="space-y-4">
            <strong class="text-lg">Clientes</strong>
            <ul>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Gestión de contactos.
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Personaliza el tipo de contacto por cliente.
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Historial de clientes
              </li>
            </ul>
          </div>
          <div v-if="selectedFeature === 'suppliers'" class="space-y-4">
            <strong class="text-lg">Proveedores</strong>
            <ul>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Tus proveedores son tus socios, lleva el registro de quienes son.
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Registra en el momento el nuevo proveedor que conociste.
              </li>
            </ul>
          </div>
          <div v-if="selectedFeature === 'services'" class="space-y-4">
            <strong class="text-lg">Servicios</strong>
            <ul>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Registra todos los servicios que ofreces
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Servicios a la medida
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Múltiples servicios en una misma reparación
              </li>
            </ul>
          </div>
          <div v-if="selectedFeature === 'finances'" class="space-y-4">
            <strong class="text-lg">Finanzas</strong>
            <ul>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Administra las finanzas de tu taller.
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Registra todos tus ingresos y gastos
              </li>
              <li class="pl-4 w-full flex flex-row mb-4">
                - Recibe reporte diario, semanal y mensual.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'SamiiFeatures',
  data() {
    return {
      selectedFeature: 'orders',
      tmpSelectedFeatureIcon: null,
      features: [
        { key: 'orders', label: 'Órdenes', image: 'bx bx-task' },
        { key: 'products', label: 'Inventarios', image: 'bx bxs-package' },
        { key: 'clients', label: 'Clientes', image: 'bx bx-user' },
        { key: 'suppliers', label: 'Proveedores', image: 'bx bxs-truck' },
        { key: 'services',label: 'Servicios', image: 'bx bxs-wrench' },
        { key: 'finances', label: 'Finanzas', image: 'bx bx-stats' }
      ],
    };
  },
  methods: {
    selectFeature(feature) {
      this.selectedFeature = feature.key;
      this.tmpSelectedFeatureIcon = feature.image;
    }
  },
};
</script>
