<template>
  <section class="text-gray-600 body-font overflow-hidden" id="pricing">
    <div class="container px-5 py-12 mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        <h1 class="sm:text-5xl text-3xl font-medium title-font mb-2">Planes</h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500"></p>
        <div class="flex mx-auto border-2 border-purple-500 rounded overflow-hidden mt-6">
          <button
            @click="quote = 'month'"
            class="py-1 px-4 focus:outline-none" :class="quote === 'month' ? 'bg-purple-500 text-white' : ''">
            Mensual
          </button>
          <button
            @click="quote = 'year'"
            class="py-1 px-4 focus:outline-none" :class="quote === 'year' ? 'bg-purple-500 text-white' : ''">
            Anual
          </button>
        </div>

        <h3 v-if="quote === 'year'" class="text-2xl font-medium title-font mb-2" style="margin-top: 20px;">Ahorro de 2 meses</h3>
      </div>
      <div class="flex flex-wrap -m-4">
        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
          <div
            class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
            <h2 class="text-2xl text-left tracking-widest title-font mb-1 font-medium">BÁSICO</h2>
            <h1 class="sm:block text-5xl leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
              <span>$ {{ quote === 'month' ? '19.99' : '16.70'}}</span>
              <span class="text-lg sm:ml-0 lg:ml-3 font-normal text-gray-500">USD / mes</span>
              <span class="sm:text-2xl md:text-2xl sm:absolute sm:right-10 md:right-0 lg:right-10">Usuarios ilimitados</span>
            </h1>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>1 sucursal
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de tareas
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de stock
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de servicios
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de clientes
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de proveedores
            </p>
            <p class="flex items-center text-gray-600 mb-12">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Soporte a usuarios
            </p>
            <div class="text-center">
              <button
                class="w-full px-8 py-3 text-lg text-white bg-purple-500 border-0 rounded shadow-2xl lg:w-2/3 focus:outline-none hover:bg-purple-600"
                @click="$router.push({ name: 'register.index' })">
                Comenzar prueba
              </button>
            </div>
          </div>
        </div>
        <div class="p-4 xl:w-1/2 md:w-1/2 w-full">
          <div
            class="h-full p-6 rounded-lg border-2 border-purple-500 flex flex-col relative overflow-hidden">
            <span class="bg-purple-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
            <h2 class="text-2xl text-left tracking-widest title-font mb-3 font-medium">PRO</h2>
            <h1 class="text-3xl text-left leading-none pb-6 mb-4 border-b border-gray-200">¡Próximamente!</h1>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>1-5 sucursales
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de tareas
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de stock
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de servicios
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de clientes
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Administración de proveedores
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Estadísticas
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Soporte de usuarios
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Finanzas
            </p>
            <p class="flex items-center text-gray-600 mb-2">
                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-600 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg>
                </span>Reportes diarios, semanales o mensuales
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { MembershipEnum } from '@/constants';

export default {
  name: 'SamiiPricing',
  data() {
    return {
      MembershipEnum,
      quote: 'year'
    }
  },
}
</script>
